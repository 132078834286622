<template>
  <div>
    <!-- 시나리오 목록 -->
    <c-table
      ref="table"
      title="시나리오 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="false"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='riskHazardPicture'">
          <q-icon
            style="font-size: 30px; cursor:pointer;"
            class="text-success" 
            name="image"
            @click.stop="openHazardPicture(props)" /> 
          <!-- <c-upload-picture
            class="scenario4m"
            height="35px"
            :attachInfo="{
              isSubmit: '',
              taskClassCd: 'RISK_HAZARD',
              taskKey: props.row.ramRiskHazardId,
            }"
          ></c-upload-picture> -->
        </template>
        <template v-else-if="col.name==='picture'">
          <q-icon
            style="font-size: 30px; cursor:pointer;"
            class="text-primary" 
            name="image"
            @click.stop="openImprPicture(props)" />
        </template>
        <template v-else-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="false"
            :requestContentsCols="requestContentsCols"
            tableKey="ramJsaAssessScenarioId"
            ibmTaskTypeCd="ITT0000015"
            ibmTaskUnderTypeCd="ITTU000020"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'jsa-scenario-TL',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'processJob' },
          { index: 2, colName: 'processJobStep' },
          { index: 3, colName: 'processJobStepHazard' },
          { index: 4, colName: 'processJobStepHazard' },
        ],
        columns: [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            // 공정
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'jobName',
            field: 'jobName',
            // 작업
            label: '작업',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'jobStepName',
            field: 'jobStepName',
            description: 'jobStepDesc',
            // 작업단계
            label: '작업단계',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'riskHazardPicture',
            field: 'riskHazardPicture',
            // 사진
            label: '사진',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'riskHazardNameClass',
            field: 'riskHazardNameClass',
            // 분류-유해위험요인
            label: '분류-유해위험요인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: '관련법규',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: '원인',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            // 결과
            label: '결과',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: '현재안전조치',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            // 개선전
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                // 빈도
                label: '빈도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                // 강도
                label: '강도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                // 위험도
                label: '위험도',
                align: 'center',
                style: 'width:50px',
                sortable: false,
                type: 'proxy',
              },
            ]
          },
          {
            name: 'picture',
            field: 'picture',
            label: '개선 전/후 사진',
            align: 'center',
            type: 'custom',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 개선대책
            label: '개선대책',
            align: 'left',
            style: 'width:150px',
            sortable: false
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:150px',
                type: 'custom',
                sortable: false
              },
            ]
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: '빈도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: '강도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: '위험도',
                align: 'center',
                style: 'width:50px',
                sortable: false,
                type: 'proxy',
              },
            ]
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: '평가일',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: '평가자',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'riskbookFlag',
            field: 'riskbookFlag',
            // 위험등록부
            label: '위험등록부',
            align: 'center',
            sortable: true,
            style: 'width:70px',
          },
        ],
        data: [],
        height: 'auto' //'600px'
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      requestImprRow: null,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    requestContentsCols() {
      return ['jobStepName', 'riskHazardNameClass', 'riskOccurrenceCause'];
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.jsa.scenario.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
    /* eslint-disable no-unused-vars */
    linkClick(data, row) {
      this.requestImprRow = row
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (result.col1.ibmStepCd === 'IS00000010' || result.col1.ibmStepCd === 'IS00000015' || result.col1.ibmStepCd === 'IS00000020') {
          this.requestImprRow.riskRegisterFlag = true
        }
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    openHazardPicture(props) {
      this.popupOptions.title = '유해위험요인 사진'; // 유해위험요인 사진
      this.popupOptions.param = {
        ramRiskHazardId: props.row.ramRiskHazardId,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = '개선 사진'; // 개선 사진
      this.popupOptions.param = {
        ramJsaAssessScenarioId: props.row.ramJsaAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/jsa/action/jsaScenarioImprPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
